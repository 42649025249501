export const importBets = (event) => {
    return new Promise((resolve) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            console.log('here')
            reader.onload = (e) => {
                try {
                    const importedData = JSON.parse(e.target.result);
                    console.log(importedData)
                    // Assuming importedData is an array of bets
                    if (Array.isArray(importedData)) {
                        // setBets(importedData);
                        resolve(importedData);
                    } else {
                        alert("Invalid data format");
                        resolve(null);
                    }
                    } catch (error) {
                        alert("Failed to import data: " + error.message);
                        resolve(null);
                    }
                };
                
                reader.readAsText(file);
        }   
    })
}