const { FURNI_NAME_NORMALIZER } = require("./enums");

const FURNI_BLACKLIST = [
    'IMPERIAL TELEPORTS',
    'MOCHAMASTER',
    'TUB MASTER',
    'STUDY DESK',
    'HC DESK',
    'HC SET',
    'NORDIC TABLE',
    'FUNKY FRIDAY: POLAR RUG',
    'FUNKY FRIDAY: PLASTO',
    'PURPLE GARLAND',
    'GREEN GARLAND',
    'GREEN TELEPORT'
]

const furniFilter = (furniName) => FURNI_BLACKLIST.indexOf(furniName) === -1

const getNormalizedName = (name) =>
    FURNI_NAME_NORMALIZER[name.toUpperCase()] || name;

const getTraderClubValuesData = async () => {
    const fetchResults = await fetch("https://tc-api.serversia.com/items");
    const results = await fetchResults.json();

    return results
    .filter(({ name }) => name)
    .filter(({ name }) => furniFilter(name.toUpperCase()))
    .filter(({ hc_val: hcValue}) => hcValue && hcValue > 0)
    .map(({ name, hc_val: hcValue }) => ({
        name: getNormalizedName(name),
        hcValue: hcValue && hcValue > 0 ? hcValue : 'N/A',
    }));
};

module.exports = {
    getTraderClubValuesData
}