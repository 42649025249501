import moment from "moment";

export const exportToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map(bet => headers.map(header => bet[header]));

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    rows.forEach(row => {
        csvContent += row.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `bets_history-${moment().format("MMMM-DD-YYYY")}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportToJSON = (data) => {
    const jsonContent = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
    const link = document.createElement('a');
    link.setAttribute('href', jsonContent);
    link.setAttribute('download', `bets_history-${moment().format("MMMM-DD-YYYY")}.json`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};