/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getTraderClubValuesData } from "./services/trader-club";
import "./App.css";
import { exportToCSV, exportToJSON } from "./services/export";
import moment from "moment";
import { importBets } from "./services/import";

function App() {
  const [bets, setBets] = useState(() => {
    const savedBets = localStorage.getItem("bets");
    return savedBets ? JSON.parse(savedBets) : [];
  });
  const [betValue, setBetValue] = useState("");
  const [result, setResult] = useState("win");
  const [betItem, setBetItem] = useState("");
  const [hcValue, setHcValue] = useState(() => {
    const savedValue = localStorage.getItem("hcValue");
    return savedValue ? JSON.parse(savedValue) : 0;
  });
  const [hcValueWon, setHcValueWon] = useState(() => {
    const savedValue = localStorage.getItem("hcValueWon");
    return savedValue ? JSON.parse(savedValue) : 0;
  });
  const [hcValueLost, setHcValueLost] = useState(() => {
    const savedValue = localStorage.getItem("hcValueLost");
    return savedValue ? JSON.parse(savedValue) : 0;
  });
  const [rareItems, setRareItems] = useState([]);
  const [rareStats, setRareStats] = useState(() => {
    const savedStats = localStorage.getItem("rareStats");
    return savedStats ? JSON.parse(savedStats) : {};
  });
  const [order, setOrder] = useState("desc");
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    const loadRares = async () => {
      const rares = await getTraderClubValuesData();
      const sortedRares = rares.sort((a, b) => a.name.localeCompare(b.name));

      setRareItems(sortedRares);
      setBetItem(sortedRares[0]?.name || "");

      if (Object.keys(rareStats).length === 0) {
        setRareStats(
          sortedRares.reduce((acc, rare) => {
            acc[rare.name] = 0;
            return acc;
          }, {})
        );
      }
    };
    loadRares();
  }, []);

  useEffect(() => {
    localStorage.setItem("bets", JSON.stringify(bets));
    localStorage.setItem("hcValue", JSON.stringify(hcValue));
    localStorage.setItem("hcValueWon", JSON.stringify(hcValueWon));
    localStorage.setItem("hcValueLost", JSON.stringify(hcValueLost));
    localStorage.setItem("rareStats", JSON.stringify(rareStats));
  }, [bets, hcValue, hcValueWon, hcValueLost, rareStats]);

  const resetHistory = () => {
    setHcValue(0);
    setHcValueWon(0);
    setHcValueLost(0);
    setBetValue("");
    setIsResetting(true);
    const zeroedOutStats = Object.keys(rareStats).reduce((acc, key) => {
      acc[key] = 0;

      return acc;
    }, {})
    setRareStats(zeroedOutStats);
    setBets([], () => {
      refreshData();
    });
    localStorage.clear();
    window.localStorage.clear();
  };

  useEffect(() => {
    if (isResetting) {
      // Check if all relevant state variables have been reset
      if (hcValue === 0 && hcValueWon === 0 && hcValueLost === 0 && bets.length === 0 && betValue === "" && Object.values(rareStats).every(value => value === 0)) {
        refreshData();
        setIsResetting(false);
      }
    }
  }, [hcValue, hcValueWon, hcValueLost, bets, betValue, rareStats, isResetting]);

  const [note, setNote] = useState("");

  const handleAddBet = () => {
    const parsedBetValue = parseInt(betValue, 10);
    if (isNaN(parsedBetValue)) {
      alert("Please enter a valid bet value");
      return;
    }

    const selectedRare = rareItems.find((item) => item.name === betItem);
    const hcValueChange = selectedRare.hcValue * parsedBetValue;
    const bet = {
      id: bets.length + 1,
      value: parsedBetValue,
      item: betItem,
      result,
      hcValue: hcValueChange,
      itemHCValue: selectedRare.hcValue,
      dateCreated: new Date().toISOString(),
      note,
    };

    setBets([...bets, bet]);
    if (result === "win") {
      setHcValue((prev) => prev + hcValueChange);
      setHcValueWon((prev) => prev + hcValueChange);
    } else {
      setHcValue((prev) => prev - hcValueChange);
      setHcValueLost((prev) => prev + hcValueChange);
    }
    setRareStats((prev) => {
      const resultValue = (result === "win" ? parsedBetValue : -parsedBetValue)
      const parsedValue = prev[betItem] ? prev[betItem] +  resultValue : resultValue;

      return {
        ...prev,
        [betItem]: parsedValue,
      }
    });
    setBetValue("");
    setNote(""); // Reset the note input field
  };

  const handleNoteChange = (id, newNote) => {
    const updatedBets = bets.map((bet) =>
      bet.id === id ? { ...bet, note: newNote } : bet
    );
    setBets(updatedBets);
  };

  const handleRemoveBet = (id) => {
    const betToRemove = bets.find((bet) => bet.id === id);
    const updatedBets = bets.filter((bet) => bet.id !== id);
    setBets(updatedBets);

    if (betToRemove.result === "win") {
      setHcValue((prev) => prev - betToRemove.hcValue);
      setHcValueWon((prev) => prev - betToRemove.hcValue);
    } else {
      setHcValue((prev) => prev + betToRemove.hcValue);
      setHcValueLost((prev) => prev - betToRemove.hcValue);
    }

    setRareStats((prev) => ({
      ...prev,
      [betToRemove.item]:
        prev[betToRemove.item] -
        (betToRemove.result === "win" ? betToRemove.value : -betToRemove.value),
    }));
  };

  const toggleOrder = () => {
    setOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const isValidBet = (bet) => {
    return (
      typeof bet.value === "number" &&
      typeof bet.result === "string" &&
      typeof bet.hcValue === "number" &&
      typeof bet.item === "string"
    );
  };

  const handleImport = async (event) => {
    console.info("File selected:", event.target.value);
    let newHcValue = 0;
    let newHcValueWon = 0;
    let newHcValueLost = 0;
    const importedData = await importBets(event);

    console.info(importedData);

    if(importedData && Array.isArray(importedData)) {
      if (importedData.every(isValidBet)) {
        const allBets = [...bets, ...importedData].map((bet, idx) => ({
          ...bet,
          id: idx + 1
        }));

        console.info(allBets);

        const updatedRareStats = rareItems.reduce((acc, rare) => {
          acc[rare.name] = 0;
          return acc;
        }, {});

        const updatedBets = allBets.map(bet => {
          const updatedRare = rareItems.find(item => item.name === bet.item);
          const newHcValueChange = updatedRare.hcValue * bet.value;
          if (bet.result === 'win') {
            newHcValue += newHcValueChange;
            newHcValueWon += newHcValueChange;
          } else {
            newHcValue -= newHcValueChange;
            newHcValueLost += newHcValueChange;
          }
          updatedRareStats[bet.item] += (bet.result === 'win' ? bet.value : -bet.value);
          return {
            ...bet,
            hcValue: newHcValueChange,
            itemHCValue: updatedRare.hcValue
          };
        });

    
        setBets(updatedBets);
        setHcValue(newHcValue);
        setHcValueWon(newHcValueWon);
        setHcValueLost(newHcValueLost);
        setRareStats(updatedRareStats);

        
        return true;
      } else {
        alert("Invalid bet data format.");
        return true;
      }
    }

    event.target.value = '';
    return true;
  }

  const refreshData = async () => {
    const rares = await getTraderClubValuesData();
    const sortedRares = rares.sort((a, b) => a.name.localeCompare(b.name));
    setRareItems(sortedRares);
    setBetItem(rares[0]?.name || '');
    const updatedRareStats = rares.reduce((acc, rare) => {
      acc[rare.name] = 0;
      return acc;
    }, {});

    let newHcValue = 0;
    let newHcValueWon = 0;
    let newHcValueLost = 0;

    console.info({
      bets
    })

    const updatedBets = bets.map(bet => {
      const updatedRare = rares.find(item => item.name === bet.item);
      const newHcValueChange = updatedRare.hcValue * bet.value;
      if (bet.result === 'win') {
        newHcValue += newHcValueChange;
        newHcValueWon += newHcValueChange;
      } else {
        newHcValue -= newHcValueChange;
        newHcValueLost += newHcValueChange;
      }
      updatedRareStats[bet.item] += (bet.result === 'win' ? bet.value : -bet.value);
      return {
        ...bet,
        hcValue: newHcValueChange,
        itemHCValue: updatedRare.hcValue
      };
    });

    setBets(updatedBets);
    setHcValue(newHcValue);
    setHcValueWon(newHcValueWon);
    setHcValueLost(newHcValueLost);
    setRareStats(updatedRareStats);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && betValue && betValue > 0 && rareItems.length > 0) {
      handleAddBet();
    }
  };

  const profitColor = hcValue === 0 ? "" : hcValue > 0 ? "green" : "red";

  const sortedBets = order === "asc" ? bets : [...bets].reverse();

  return (
    <div className="App">
      <h1 className="green">Arcade Tracker</h1>
      <h2 className="green tac">
        Brought to you by: franchise &{" "}
        <a
          target="_blank"
          href="https://discord.gg/originshub"
          rel="noreferrer"
        >
          OriginsHub
        </a>
      </h2>
      <h3 className="green tac">
        Special thanks to:{" "}
        <a target="_blank" href="https://traderclub.gg" rel="noreferrer">
          TraderClub
        </a>
      </h3>
      <div className="bet-form">
        <input
          type="number"
          value={betValue}
          onChange={(e) => setBetValue(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter bet value"
        />
        <select value={result} onChange={(e) => setResult(e.target.value)}>
          <option value="win">Win</option>
          <option value="lose">Lose</option>
        </select>
        <select
          style={{ width: "142px" }}
          value={betItem}
          onChange={(e) => setBetItem(e.target.value)}
        >
          {rareItems.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Enter a note"
          className="note-input"
          onKeyPress={handleKeyPress}
        />
        <button disabled={rareItems.length === 0 || (!betValue || 0 > betValue)} onClick={handleAddBet}>
          Add Bet
        </button>
        {/* <div className="refresh-button-container">
          <button onClick={refreshData}>Refresh Data</button>
        </div> */}
      </div>
      <div className="summary">
        <h2>Summary</h2>
        <p>
          Total HC Profit:{" "}
          <span className={profitColor}>{hcValue.toFixed(2)}</span>
        </p>
        <p>HC Won: {hcValueWon.toFixed(2)}</p>
        <p>HC Lost: {hcValueLost.toFixed(2)}</p>
      </div>
      <div className="table-container">
        <div className="table-header">
          <h2 className="tac">Bet History</h2>
          <div className="export-buttons">
            <button onClick={() => exportToCSV(bets)}>Export to CSV</button>
            <label htmlFor="import-bets" className="export-button">Import JSON</label>
            <input
              type="file"
              id="import-bets"
              accept=".json"
              onChange={handleImport}
              style={{ display: 'none' }}
            />
            <button onClick={() => exportToJSON(bets)}>Export to JSON</button>
            <button onClick={() => resetHistory()}>Reset</button>
            <button style={{ width: "150px" }} onClick={toggleOrder}>
              {order === "asc" ? "Sort Descending" : "Sort Ascending"}
            </button>
          </div>
        </div>
        <div className="table-wrapper bet-results-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Bet Value</th>
                <th>Item</th>
                <th>Result</th>
                <th>Net HC Value +/-</th>
                <th>Item HC Value</th>
                <th>Date</th>
                <th>Note</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedBets.map((bet) => (
                <tr key={bet.id + '-' + Math.random()}>
                  <td>{bet.id}</td>
                  <td>{bet.value}</td>
                  <td>{bet.item}</td>
                  <td>{bet.result}</td>
                  <td>
                    <span className={bet.result === "win" ? "green" : "red"}>
                      {bet.hcValue.toFixed(2)}
                    </span>
                  </td>
                  <td>{bet.itemHCValue}</td>
                  <td>
                    {bet.dateCreated
                      ? moment(bet.dateCreated).format("MMMM DD YYYY kk:mm:ss")
                      : "N/A"}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={bet.note || ""}
                      onChange={(e) => handleNoteChange(bet.id, e.target.value)}
                      placeholder="Add a note"
                      className="note-input"
                    />
                  </td>
                  <td>
                    <button onClick={() => handleRemoveBet(bet.id)}>
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-container">
        <h2 className="rare-stats-text tac">Rare Stats</h2>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Rare Item</th>
                <th>Net Change</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rareStats).map(([item, change]) => {
                const netChangeColor =
                  change === 0 ? "" : change > 0 ? "green" : "red";

                return (
                  <tr key={item}>
                    <td>{item}</td>
                    <td>
                      <span className={netChangeColor}>{change}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default App;
