const NON_NUMBER_VALUES = ["PRICELESS", "TBA", "N/A", 0];

const MESSAGE_ID = "1259718936732241941";

const FURNI_NAME_ENUM = {
  BRONZE_HABBO_TROPHY: "Bronze Habbo Trophy",
  COLA_MACHINE: "Cola Machine",
  CLUB_SOFA: "Club Sofa",
  DICE_MASTER: "Dice Master",
  FF_PLASTO: "FF Plasto",
  FF_POD: "FF Pod",
  FF_BEAR_RUG: "FF Bear Rug",
  FF_GREEN_TELE: 'FF Green Tele',
  FF_GARLAND: 'FF Garland',
  GREEN_GARLAND: "Green Garland",
  HC_SET: "HC Set",
  IMPERIAL_TELEPORT: "Imperial Teleport",
  MAJESTIC_CHAIR: "Majestic Chair",
  MOCHAMASTER: "Mochamaster",
  NORDIC_TABLE: "Nordic Table",
  OIL_LAMP: "Oil Lamp",
  PETAL_PATCH: "Petal Patch",
  PURPLE_GARLAND: "Purple Garland",
  PURPLE_PILLOW: "Purple Pillow",
  SMALL_TICKET_BUNDLE: "Small Ticket Bundle",
  STUDY_DESK: "Study Desk",
  THRONE_SOFA: "Throne Sofa",
  TUB_MASTER: "Tub Master",
  DINO_EGG: "Dino",
  GOLD_ICE_CREAM_MAKER: 'Gold ICM'
};

const DUCKET_URL_TO_NAME_NORMALIZER = {
  'https://api.ducket.net/assets/furni/club_sofa_icon.png': FURNI_NAME_ENUM.CLUB_SOFA,
  'https://api.ducket.net/assets/furni/rare_icecream_5_icon.png': FURNI_NAME_ENUM.GOLD_ICE_CREAM_MAKER,
  'https://api.ducket.net/assets/furni/pillow_7_icon.png': FURNI_NAME_ENUM.PURPLE_PILLOW,
  'https://api.ducket.net/assets/furni/spyro_icon.png': FURNI_NAME_ENUM.DINO_EGG,
  'https://www.ducket.net/images/icons/ph-box.png': FURNI_NAME_ENUM.SMALL_TICKET_BUNDLE,
  'https://api.ducket.net/assets/furni/rare_daffodil_rug_icon.png': FURNI_NAME_ENUM.PETAL_PATCH,
  'https://api.ducket.net/assets/furni/hcsohva_icon.png': FURNI_NAME_ENUM.THRONE_SOFA,
  'https://api.ducket.net/assets/furni/edicehc_icon.png': FURNI_NAME_ENUM.DICE_MASTER,
  'https://api.ducket.net/assets/furni/md_limukaappi_icon.png': FURNI_NAME_ENUM.COLA_MACHINE,
  'https://api.ducket.net/assets/furni/hc_chr_icon.png': FURNI_NAME_ENUM.MAJESTIC_CHAIR,
  'https://api.ducket.net/assets/furni/hc_lmp_icon.png': FURNI_NAME_ENUM.OIL_LAMP,
  'https://api.ducket.net/assets/furni/doorD_icon.png': FURNI_NAME_ENUM.IMPERIAL_TELEPORT
}

const EMOJI_CODES = {
  [FURNI_NAME_ENUM.THRONE_SOFA]: "1259571424222253156",
  [FURNI_NAME_ENUM.STUDY_DESK]: "1259574900625248267",
  [FURNI_NAME_ENUM.PURPLE_PILLOW]: "1259558866669142036",
  [FURNI_NAME_ENUM.SMALL_TICKET_BUNDLE]: "1259557644671254579",
  [FURNI_NAME_ENUM.OIL_LAMP]: "1259574327397847130",
  [FURNI_NAME_ENUM.NORDIC_TABLE]: "1259572668865449985",
  [FURNI_NAME_ENUM.MAJESTIC_CHAIR]: "1259573767361921075",
  [FURNI_NAME_ENUM.IMPERIAL_TELEPORT]: "1259570871614574672",
  [FURNI_NAME_ENUM.TUB_MASTER]: "1259568585060257914",
  [FURNI_NAME_ENUM.CLUB_SOFA]: "1259568010809577612",
  [FURNI_NAME_ENUM.MOCHAMASTER]: "1259569189396545640",
  [FURNI_NAME_ENUM.FF_POD]: "1259566356789985290",
  [FURNI_NAME_ENUM.GREEN_GARLAND]: "1259564809796718623",
  [FURNI_NAME_ENUM.PURPLE_GARLAND]: "1259564335999615121",
  [FURNI_NAME_ENUM.FF_GARLAND]: '1259564335999615121',
  [FURNI_NAME_ENUM.FF_GREEN_TELE]: '1261397156636528802',
  [FURNI_NAME_ENUM.FF_PLASTO]: '1259716519877804094',
  [FURNI_NAME_ENUM.FF_BEAR_RUG]: "1259561379086733342",
  [FURNI_NAME_ENUM.DICE_MASTER]: "1259570063816790212",
  [FURNI_NAME_ENUM.BRONZE_HABBO_TROPHY]: "1259562671607386142",
  [FURNI_NAME_ENUM.COLA_MACHINE]: "1259710749115289631",
  [FURNI_NAME_ENUM.PETAL_PATCH]: "1259559721631748217",
  [FURNI_NAME_ENUM.HC_SET]: '1259716454123835454',
  [FURNI_NAME_ENUM.DINO_EGG]: '1260057156745564191',
  [FURNI_NAME_ENUM.GOLD_ICE_CREAM_MAKER]: '1262583735044538410',
  UNKNOWN: "1259560250881867806",
};

const FURNI_GROUPS = {
  CASINO_RARES: [
    FURNI_NAME_ENUM.CLUB_SOFA,
    FURNI_NAME_ENUM.GOLD_ICE_CREAM_MAKER,
    FURNI_NAME_ENUM.DINO_EGG,
    FURNI_NAME_ENUM.COLA_MACHINE,
    FURNI_NAME_ENUM.PURPLE_PILLOW,
    FURNI_NAME_ENUM.PETAL_PATCH,
    FURNI_NAME_ENUM.MAJESTIC_CHAIR,
    FURNI_NAME_ENUM.THRONE_SOFA,
    FURNI_NAME_ENUM.DICE_MASTER
  ],
  SUPER_RARES: [
    FURNI_NAME_ENUM.BRONZE_HABBO_TROPHY,
    FURNI_NAME_ENUM.SMALL_TICKET_BUNDLE,
  ],
  FF_RARES: [
    FURNI_NAME_ENUM.FF_GREEN_TELE,
    // FURNI_NAME_ENUM.GREEN_GARLAND,
    // FURNI_NAME_ENUM.PURPLE_GARLAND,
    FURNI_NAME_ENUM.FF_GARLAND,
    FURNI_NAME_ENUM.FF_BEAR_RUG,
    FURNI_NAME_ENUM.FF_POD,
    FURNI_NAME_ENUM.FF_PLASTO,
  ],
  HC_RARES: [
    FURNI_NAME_ENUM.HC_SET,
    FURNI_NAME_ENUM.IMPERIAL_TELEPORT,
    FURNI_NAME_ENUM.MOCHAMASTER,
    FURNI_NAME_ENUM.NORDIC_TABLE,
    FURNI_NAME_ENUM.OIL_LAMP,
    FURNI_NAME_ENUM.TUB_MASTER,
    FURNI_NAME_ENUM.STUDY_DESK,
  ],
};

const FURNI_NAME_NORMALIZER = {
  "BONNIE BLONDE'S PILLOW": FURNI_NAME_ENUM.PURPLE_PILLOW,
  "BRONZE HABBO TROPHY": FURNI_NAME_ENUM.BRONZE_HABBO_TROPHY,
  "COLA MACHINE": FURNI_NAME_ENUM.COLA_MACHINE,
  "CLUB SOFA": FURNI_NAME_ENUM.CLUB_SOFA,
  DICEMASTER: FURNI_NAME_ENUM.DICE_MASTER,
  "DICE MASTER": FURNI_NAME_ENUM.DICE_MASTER,
  "DRAGON EGG": FURNI_NAME_ENUM.DINO_EGG,
  "FUNKY FRIDAY CHAIR": FURNI_NAME_ENUM.FF_PLASTO,
  "FUNKY FRIDAY: POLAR RUGS": FURNI_NAME_ENUM.FF_BEAR_RUG,
  "FAUX-FUR BEAR RUG": FURNI_NAME_ENUM.FF_BEAR_RUG,
  "FUNKY FRIDAY POD": FURNI_NAME_ENUM.FF_POD,
  "FUNKY FRIDAY: PLASTO": FURNI_NAME_ENUM.FF_PLASTO,
  'GREEN TELEPORT': FURNI_NAME_ENUM.FF_GREEN_TELE,
  'GREEN TELEPORT (SET)': FURNI_NAME_ENUM.FF_GREEN_TELE,
  'GREEN GARLAND': FURNI_NAME_ENUM.GREEN_GARLAND,
  'GOLD ICECREAM MAKER': FURNI_NAME_ENUM.GOLD_ICE_CREAM_MAKER,
  'GOLD ICE CREAM MAKER': FURNI_NAME_ENUM.GOLD_ICE_CREAM_MAKER,
  "HABBO COLA": FURNI_NAME_ENUM.COLA_MACHINE,
  "HC PLASTIC SET": FURNI_NAME_ENUM.HC_SET,
  "HC PLASTO SET": FURNI_NAME_ENUM.HC_SET,
  "HC SET": FURNI_NAME_ENUM.HC_SET,
  // "GREEN GARLAND": FURNI_NAME_ENUM.GREEN_GARLAND,
  "IMPERIAL TELEPORT": FURNI_NAME_ENUM.IMPERIAL_TELEPORT,
  "IMPERIAL TELEPORT (SET)": FURNI_NAME_ENUM.IMPERIAL_TELEPORT,
  "IMPERIAL TELEPORTS": FURNI_NAME_ENUM.IMPERIAL_TELEPORT,
  "IMPERIAL TELEPORTS (SET)": FURNI_NAME_ENUM.IMPERIAL_TELEPORT,
  "MAJESTIC CHAIR": FURNI_NAME_ENUM.MAJESTIC_CHAIR,
  MOCHAMASTER: FURNI_NAME_ENUM.MOCHAMASTER,
  MOCHAMASTERS: FURNI_NAME_ENUM.MOCHAMASTER,
  "NORDIC TABLE": FURNI_NAME_ENUM.NORDIC_TABLE,
  "OIL LAMP": FURNI_NAME_ENUM.OIL_LAMP,
  "PETAL PATCH": FURNI_NAME_ENUM.PETAL_PATCH,
  // "PURPLE GARLAND": FURNI_NAME_ENUM.PURPLE_GARLAND,
  "PURPLE GARLAND": FURNI_NAME_ENUM.FF_GARLAND,
  "PURPLE VELVET PILLOW": FURNI_NAME_ENUM.PURPLE_PILLOW,
  "SMALL TICKET BUNDLE": FURNI_NAME_ENUM.SMALL_TICKET_BUNDLE,
  "SMALL TICKET BUNDLE (PH BOX)": FURNI_NAME_ENUM.SMALL_TICKET_BUNDLE,
  "STUDY DESK": FURNI_NAME_ENUM.STUDY_DESK,
  "THRONE SOFA": FURNI_NAME_ENUM.THRONE_SOFA,
  TUBMASTER: FURNI_NAME_ENUM.TUB_MASTER,
  "TUB MASTER": FURNI_NAME_ENUM.TUB_MASTER,
};

module.exports = {
    DUCKET_URL_TO_NAME_NORMALIZER,
    EMOJI_CODES,
    FURNI_GROUPS,
    FURNI_NAME_ENUM,
    FURNI_NAME_NORMALIZER,
    NON_NUMBER_VALUES,
    MESSAGE_ID
}